import { VDataTable } from 'vuetify/lib/components/VDataTable';

var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('b-modal',{attrs:{"id":"modal-level","title":_vm.title,"size":_vm.form.type ? 'xl' : 'md',"centered":"","hide-footer":""},on:{"hidden":_vm.resetModal},model:{value:(_vm.show),callback:function ($$v) {_vm.show=$$v},expression:"show"}},[_c('b-overlay',{attrs:{"show":_vm.loading}},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.handleValidate.apply(null, arguments)}}},[_c('b-row',[_c('b-col',{class:{ 'border-right': _vm.form.type }},[_c('b-row',[_c('b-col',[_c('basic-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  alpha_num: true,
                  max: 50,
                }),expression:"{\n                  required: true,\n                  alpha_num: true,\n                  max: 50,\n                }"}],attrs:{"required":"","label":"Mã Câu hỏi","placeholder":"Nhập mã câu hỏi","name":"code","state":_vm.validateState('code'),"invalidFeedback":_vm.errors.first('code'),"data-vv-as":"Mã câu hỏi","value":_vm.form.code,"maxLength":"50","disabled":_vm.isDetail},on:{"update:value":function($event){return _vm.$set(_vm.form, "code", $event)}}})],1),_c('b-col',[_c('basic-select',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                }),expression:"{\n                  required: true,\n                }"}],attrs:{"label":"Loại câu hỏi","placeholder":"--- Chọn loại câu hỏi ---","required":"","name":"type","solid":false,"allowEmpty":false,"value-label":"text","track-by":"value","state":_vm.validateState('type'),"invalidFeedback":_vm.errors.first('type'),"data-vv-as":"Loại câu hỏi","options":_vm.answerTypes,"value":_vm.form.type,"disabled":_vm.isDetail},on:{"update:value":[function($event){return _vm.$set(_vm.form, "type", $event)},_vm.selectType]}})],1)],1),_c('b-row',[_c('b-col',[_c('basic-select',{attrs:{"label":"Trạng thái","placeholder":"--- Chọn trạng thái ---","name":"status","solid":false,"allowEmpty":false,"value-label":"text","track-by":"value","options":_vm.statuses,"disabled":"","value":_vm.form.status},on:{"update:value":[function($event){return _vm.$set(_vm.form, "status", $event)},_vm.selectStatus]}})],1)],1),_c('b-row',[_c('b-col',[_c('basic-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                  max: 500,
                }),expression:"{\n                  required: true,\n                  max: 500,\n                }"}],attrs:{"required":"","label":"Tên câu hỏi","placeholder":"Nhập tên câu hỏi","name":"title","state":_vm.validateState('title'),"invalidFeedback":_vm.errors.first('title'),"data-vv-as":"Tên câu hỏi","value":_vm.form.name,"disabled":_vm.isDetail,"maxlength":"500"},on:{"update:value":function($event){return _vm.$set(_vm.form, "name", $event)}}})],1)],1),_c('b-row',[_c('b-col',[_c('basic-select',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                }),expression:"{\n                  required: true,\n                }"}],attrs:{"label":"Cấp độ","placeholder":"--- Chọn cấp độ liên quan ---","name":"level","solid":false,"allowEmpty":true,"value-label":"text","track-by":"value","multiple":"","required":"","state":_vm.validateState('level'),"invalidFeedback":_vm.errors.first('level'),"scope":"level","data-vv-as":"Cấp độ liên quan","options":_vm.levels,"value":_vm.form.lstLevelId,"disabled":_vm.isDetail},on:{"update:value":[function($event){return _vm.$set(_vm.form, "lstLevelId", $event)},_vm.selectLevel]}})],1)],1),_c('b-row',[_c('b-col',[_c('basic-select',{directives:[{name:"validate",rawName:"v-validate",value:({
                  required: true,
                }),expression:"{\n                  required: true,\n                }"}],attrs:{"label":"Bài học liên quan","placeholder":"--- Chọn bài học liên quan ---","name":"lesson","solid":false,"allowEmpty":true,"value-label":"text","track-by":"value","multiple":"","required":"","state":_vm.validateState('lesson'),"invalidFeedback":_vm.errors.first('lesson'),"data-vv-as":"Bài học liên quan","options":_vm.lessons,"disabled":_vm.isDetail,"value":_vm.form.lstLessonId},on:{"update:value":[function($event){return _vm.$set(_vm.form, "lstLessonId", $event)},_vm.selectLesson]}})],1)],1)],1),_c('b-col',{directives:[{name:"show",rawName:"v-show",value:(_vm.form.type),expression:"form.type"}]},[_c('div',{staticClass:"mb-8"},[_c('label',{staticClass:"font-weight-bolder mb-3"},[_vm._v("Định nghĩa câu trả lời và đáp an đúng cho câu hỏi")]),_c(VDataTable,{ref:"sortableTable",attrs:{"headers":_vm.headers,"items":_vm.quizes,"id":"sortable","hide-default-footer":"","items-per-page":-1},scopedSlots:_vm._u([{key:"item",fn:function(ref){
                var item = ref.item;
return [(!item.isAddNew)?_c('tr',{key:_vm.itemKey(item),staticClass:"cursor-pointer sortableRow position-static"},[(!_vm.isDetail)?_c('td',{staticClass:"mw-100 handle text-overflow"},[_c('div',{staticClass:"d-flex w-100 align-items-center"},[_c('div',{staticClass:"svg-icon svg-icon-sm text-overflow"},[_c('inline-svg',{staticClass:"svg-icon mr-2 mw-30 sortHandle",attrs:{"src":"/media/svg/icons/Neolex/Basic/menu-dots.svg"}})],1)])]):_vm._e(),_c('td',[_vm._v(_vm._s(item.name))]),_c('td',{staticClass:"text-center"},[(_vm.isSingleChoices)?_c('b-form-radio',{staticClass:"radio-answer",attrs:{"size":"lg","value":true,"name":"some-radios","disabled":_vm.isDetail},on:{"change":function($event){return _vm.changeAnswerCorrect(item)}},model:{value:(item.isCorrect),callback:function ($$v) {_vm.$set(item, "isCorrect", $$v)},expression:"item.isCorrect"}}):_c('b-form-checkbox',{staticClass:"checkbox-answer",attrs:{"disabled":_vm.isDetail},model:{value:(item.isCorrect),callback:function ($$v) {_vm.$set(item, "isCorrect", $$v)},expression:"item.isCorrect"}})],1),(!_vm.isDetail)?_c('td',[_c('b-button',{staticClass:"btn btn-delete ml-0",attrs:{"size":"sm","type":"button"},on:{"click":function($event){return _vm.removeAnswer(item)}}},[_c('span',{staticClass:"svg-icon mr-0"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Basic/trash-2.svg"}})],1)])],1):_vm._e()]):_c('tr',{staticClass:"cursor-pointer sortableRow position-static"},[_c('td'),_c('td',[_c('div',{staticClass:"py-3"},[_c('basic-input',{directives:[{name:"validate",rawName:"v-validate",value:({
                          required: _vm.isRequireAnswer,
                          max: 500,
                        }),expression:"{\n                          required: isRequireAnswer,\n                          max: 500,\n                        }"}],ref:"addAnswer",staticClass:"score-input",attrs:{"name":"add-answer","value":_vm.quize.name,"required":true,"state":_vm.validateState("add-answer"),"invalid-feedback":_vm.errors.first("add-answer"),"data-vv-as":"Câu trả lời","placeholder":"Thêm câu trả lời","maxlength":"500"},on:{"update:value":function($event){return _vm.$set(_vm.quize, "name", $event)}}})],1)]),_c('td',{staticClass:"text-center"},[(_vm.isSingleChoices)?_c('b-form-radio',{staticClass:"radio-answer",attrs:{"size":"lg","value":true,"name":"some-radios"},model:{value:(item.isCorrect),callback:function ($$v) {_vm.$set(item, "isCorrect", $$v)},expression:"item.isCorrect"}}):_c('b-form-checkbox',{staticClass:"checkbox-answer",model:{value:(item.isCorrect),callback:function ($$v) {_vm.$set(item, "isCorrect", $$v)},expression:"item.isCorrect"}})],1),_c('td',[_c('b-button',{staticClass:"btn btn-save ml-0",attrs:{"size":"sm","type":"button"},on:{"click":function($event){return _vm.addAnswer(item)}}},[_c('span',{staticClass:"svg-icon mr-0"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Basic/save.svg"}})],1)])],1)])]}}])}),(_vm.showErrorAnswers)?_c('span',{staticClass:"text-danger"},[_vm._v("Câu trả lời cho câu hỏi là chưa phù hợp")]):_vm._e()],1),_c('basic-text-area',{directives:[{name:"validate",rawName:"v-validate",value:({
              required: _vm.requiredExplain,
            }),expression:"{\n              required: requiredExplain,\n            }"}],attrs:{"label":"Câu giải thích cho đáp án đúng","placeholder":"Nhập câu giải thích cho các đáp án đúng cho câu hỏi","name":"description","value":_vm.form.explain,"disabled":_vm.isDetail,"maxlength":"500","required":_vm.requiredExplain,"state":_vm.validateState("description"),"invalid-feedback":_vm.errors.first("description"),"data-vv-as":"Câu giải thích cho đáp án đúng"},on:{"update:value":function($event){return _vm.$set(_vm.form, "explain", $event)}}})],1)],1),(!_vm.isDetail)?_c('div',{staticClass:"d-flex justify-content-end align-items-center"},[_c('b-button',{staticClass:"btn",attrs:{"type":"button"},on:{"click":_vm.resetModal}},[_vm._v(" Huỷ ")]),(_vm.isEdit)?[(_vm.showBtnActive)?_c('b-button',{staticClass:"btn btn-primary ml-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.showPopupChangeStatus(1)}}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Basic/power.svg"}})],1),_vm._v(" Active ")]):_c('b-button',{staticClass:"btn btn-danger ml-3",attrs:{"type":"button"},on:{"click":function($event){return _vm.showPopupChangeStatus(0)}}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Basic/power.svg"}})],1),_vm._v(" Inactive ")])]:_vm._e(),_c('b-button',{staticClass:"btn btn-success ml-3",attrs:{"type":"submit"}},[_c('span',{staticClass:"svg-icon"},[_c('inline-svg',{attrs:{"src":"/media/svg/icons/Neolex/Basic/save.svg"}})],1),_vm._v(" Lưu ")])],2):_vm._e()],1)])],1)}
var staticRenderFns = []

export { render, staticRenderFns }